
import Vue, { PropType } from 'vue';
import { MultipollChoiceEnum } from '@/api/multipoll';

interface State {
  input: number | null,
}

export default Vue.extend({
  name: 'MultipollChoiceInput',
  props: {
    value: {
      type: String as PropType<MultipollChoiceEnum>,
    },
  },
  data(): State {
    return {
      input: this.value ? Object.values(MultipollChoiceEnum).indexOf(this.value) : null,
    };
  },
  watch: {
    value(newValue) {
      this.input = newValue ? Object.values(MultipollChoiceEnum).indexOf(newValue) : null;
    },
    input(newValue) {
      switch (newValue) {
        case 0:
          this.$emit('input', MultipollChoiceEnum.Yes);
          break;
        case 1:
          this.$emit('input', MultipollChoiceEnum.Maybe);
          break;
        case 2:
          this.$emit('input', MultipollChoiceEnum.No);
          break;
        default:
          this.$emit('input', null);
      }
    },
  },
});
