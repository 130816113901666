
import Vue, { PropType } from 'vue';
import { MultipollAnswer, MultipollChoiceEnum, MultipollChoiceInput } from '@/api/multipoll';

export default Vue.extend({
  name: 'MultipollAnotherPerson',
  props: {
    date: {
      type: String,
      required: true,
    },
    multipollAnswer: {
      type: Object as PropType<MultipollAnswer>,
      required: true,
    },
  },
  computed: {
    currentDateInput(): MultipollChoiceInput | undefined {
      return this.multipollAnswer.multipollChoiceInputs.find(input => input.date === this.date);
    },
    choiceYes(): boolean {
      return this.currentDateInput?.choice === MultipollChoiceEnum.Yes;
    },
    choiceMaybe(): boolean {
      return this.currentDateInput?.choice === MultipollChoiceEnum.Maybe;
    },
    choiceNo(): boolean {
      return this.currentDateInput?.choice === MultipollChoiceEnum.No;
    },

  },
});
