
import Vue from 'vue';
import { maxLength } from 'vuelidate/lib/validators';
import InputValidationMixin from '@/mixins/Form/InputValidationMixin';

interface State {
  input: string,
}

export default Vue.extend({
  name: 'EventDescriptionInput',
  mixins: [InputValidationMixin],
  props: {
    value: String,
  },
  data(): State {
    return {
      input: this.value,
    };
  },
  validations() {
    return {
      input: { maxLength: maxLength(500) },
    };
  },
  computed: {
    inputErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.input.$dirty) {
        return errors;
      }

      if (!this.$v.input.maxLength) {
        errors.push('Event title must be maximum 500 characters!');
      }

      return errors;
    },
  },
  watch: {
    value(newValue) {
      this.input = newValue;
      this.$v.$reset();
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
    },
  },
});
