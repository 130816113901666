import Vue from 'vue';

export default Vue.extend({
  methods: {
    setPageTitle(title: string): void {
      Vue.nextTick(() => {
        document.title = title;
      });
    },
  },
});
