
import Vue, { VueConstructor } from 'vue';
import EventTitleInput from '@/components/Multipoll/Create/EventTitleInput.vue';
import ContactNameInput from '@/components/Multipoll/Create/ContactNameInput.vue';
import ContactEmailInput from '@/components/Multipoll/Create/ContactEmailInput.vue';
import EventDescriptionInput from '@/components/Multipoll/Create/EventDescriptionInput.vue';
import MultiDatePicker from '@/components/Multipoll/Create/MultiDatePicker.vue';
import MultipollTokensMixin from '@/mixins/Multipoll/MultipollTokensMixin.vue';

interface State {
  title: string | null,
  description: string | null,
  contactName: string | null,
  contactEmail: string | null,
  multipollDates: Array<string>,
  titleInputValid: boolean,
  descriptionInputValid: boolean,
  contactNameInputValid: boolean,
  contactEmailInputValid: boolean,
  multipollDatesInputValid: boolean,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof MultipollTokensMixin>>).extend({
  name: 'MultipollCreateForm',
  components: {
    MultiDatePicker,
    EventDescriptionInput,
    ContactEmailInput,
    ContactNameInput,
    EventTitleInput,
  },
  mixins: [MultipollTokensMixin],
  data(): State {
    return {
      title: null,
      description: null,
      contactName: null,
      contactEmail: null,
      multipollDates: [],
      titleInputValid: false,
      descriptionInputValid: true, // Optional
      contactNameInputValid: false,
      contactEmailInputValid: true, // Optional
      multipollDatesInputValid: false,
    };
  },
  computed: {
    valid(): boolean {
      return this.titleInputValid
        && this.descriptionInputValid
        && this.contactNameInputValid
        && this.contactEmailInputValid
        && this.multipollDatesInputValid;
    },
  },
  methods: {
    createMultipoll() {
      this.$apiMultipoll.createMultipoll({
        title: this.title || '',
        description: this.description || undefined,
        contactName: this.contactName || undefined,
        contactEmail: this.contactEmail || undefined,
        multipollDates: this.multipollDates,
      })
        .then(response => {
          console.log('multipoll created successfully', response);
          this.setAuthToken(response.data.multipoll.id, response.data.authToken);
          this.setAnswerToken(response.data.multipoll.id, response.data.answerToken);
          this.$router.push({
            name: 'multipoll.summary',
            params: {
              multipollId: response.data.multipoll.id,
              multipollTitle: response.data.multipoll.title,
              createSuccess: 'true',
            },
          });
        })
        .catch(error => {
          console.error('multipoll create failed', error);
        });
    },
  },
});
