
import Vue, { VueConstructor } from 'vue';
import { MultipollAnswer, MultipollInfo } from '@/api/multipoll';
import MultipollSubmitForm from '@/components/Multipoll/Submit/MultipollSubmitForm.vue';
import AnswerTokenMixin from '@/mixins/Multipoll/MultipollTokensMixin.vue';
import PageTitleMixin from '@/mixins/PageTitleMixin';

interface State {
  overlay: boolean,
  multipoll: MultipollInfo | undefined,
  errorReason: string | undefined,
  answer: MultipollAnswer | undefined,
  answerToken: string | undefined,
  otherAnswers: MultipollAnswer[],
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof AnswerTokenMixin> & InstanceType<typeof PageTitleMixin>>).extend({
  name: 'MultipollSubmit',
  components: { MultipollSubmitForm },
  mixins: [AnswerTokenMixin, PageTitleMixin],
  created() {
    this.answerToken = this.getAnswerToken(this.multipollId);

    this.$apiMultipoll.getMultipollInfo(this.multipollId, this.answerToken)
      .then(response => {
        this.multipoll = response.data.multipoll;
        this.answer = response.data.answer;
        this.otherAnswers = response.data.otherAnswers;
      })
      .catch(error => {
        console.log(error);
        this.errorReason = error?.reason ?? 'Failed to load, please recheck the URL';
      });
  },
  mounted() {
    this.setPageTitle('Brontly multipoll');
  },
  data(): State {
    return {
      overlay: false,
      multipoll: undefined,
      errorReason: undefined,
      answer: undefined,
      answerToken: undefined,
      otherAnswers: [],
    };
  },
  computed: {
    multipollId() {
      return this.$route.params.multipollId;
    },
  },
  watch: {
    multipoll(newValue) {
      if (newValue.title) {
        this.setPageTitle(`Brontly multipoll - ${newValue.title}`);
      }
    },
  },
});
