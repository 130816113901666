
import Vue, { PropType } from 'vue';
import { MultipollChoiceEnum, MultipollChoiceInput as MultipollChoiceInputType } from '@/api/multipoll';

export default Vue.extend({
  name: 'MultipollSummaryHeader',
  props: {
    multipollChoiceInputs: {
      type: Array as PropType<MultipollChoiceInputType[]>,
      required: true,
    },
  },
  computed: {
    choiceYesCount(): number {
      return this.multipollChoiceInputs.filter(input => input.choice === MultipollChoiceEnum.Yes).length;
    },
    choiceMaybeCount(): MultipollChoiceInputType[] {
      return this.multipollChoiceInputs.filter(input => input.choice === MultipollChoiceEnum.Maybe);
    },
    choiceNoCount(): MultipollChoiceInputType[] {
      return this.multipollChoiceInputs.filter(input => input.choice === MultipollChoiceEnum.No);
    },

  },
});
