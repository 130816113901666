
import Vue, { VueConstructor } from 'vue';
import PageTitleMixin from '@/mixins/PageTitleMixin';

interface State {
  snackbar: boolean,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof PageTitleMixin>>).extend({
  name: 'MultipollSummary',
  mixins: [PageTitleMixin],
  created() {
    if (!this.createSuccess && !this.submitSuccess && !this.debugSuccess) {
      console.log('redirecting to submit view');
      this.$router.push({ name: 'multipoll.submit', params: { multipollId: this.multipollId } });
    }
  },
  mounted() {
    if (this.multipollTitle) {
      this.setPageTitle(`Brontly multipoll - ${this.multipollTitle}`);
    } else {
      this.setPageTitle('Brontly multipoll');
    }
  },
  data():State {
    return {
      snackbar: false,
    };
  },
  computed: {
    multipollId(): string {
      return this.$route.params.multipollId;
    },
    multipollTitle(): string | null {
      return this.$route.params.multipollTitle;
    },
    createSuccess(): boolean {
      return this.$route.params.createSuccess === 'true';
    },
    submitSuccess(): boolean {
      return this.$route.params.submitSuccess === 'true';
    },
    debugSuccess(): boolean {
      return this.$route.query.debugSuccess === 'true';
    },
    shareUrl(): string {
      return `${window.location.origin}/multipoll/${this.multipollId}`;
    },
  },
  methods: {
    copyUrlToClipboard() {
      navigator.clipboard.writeText(this.shareUrl);
      this.snackbar = true;
    },
  },
});
