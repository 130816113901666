
import Vue, { PropType } from 'vue';
import { MultipollAnswer, MultipollChoiceInput, MultipollInfo } from '@/api/multipoll';
import MultipollChoiceCard from '@/components/Multipoll/Submit/MultipollChoiceCard.vue';

interface State {
  inputs: MultipollChoiceInput[],
}

export default Vue.extend({
  name: 'MultipollChoiceInputs',
  components: { MultipollChoiceCard },
  props: {
    multipoll: {
      type: Object as PropType<MultipollInfo>,
      required: true,
    },
    otherAnswers: {
      type: Array as PropType<MultipollAnswer[]>,
      required: true,
    },
    value: {
      type: Array as PropType<MultipollChoiceInput[]>,
      required: true,
    },
  },
  data(): State {
    return {
      inputs: this.value,
    };
  },
  methods: {
    findInputForDate(date: string): MultipollChoiceInput | undefined {
      return this.value.find(input => input.date === date);
    },
    onChoiceInput(newValue: MultipollChoiceInput): void {
      // TODO Data is passed to parent via the array from props, this is not a correct way

      const index = this.inputs.findIndex(input => input.date === newValue.date);

      if (index >= 0) {
        this.inputs.splice(index, 1);
      }

      this.inputs.push(newValue);
    },
  },
  watch: {
    inputs: {
      immediate: true,
      handler(newValue: MultipollChoiceInput[]) {
        if (newValue.find(input => !input.choice)) {
          // At least one input date has not been filled yet
          this.$emit('valid', false);
        } else {
          // All inputs have choice, the valid state depends on how many of them have been filled
          this.$emit('valid', this.multipoll.multipollChoices.length === newValue.length);
        }
      },
    },
  },
});
