
import Vue, { VueConstructor } from 'vue';
import MultipollCreateForm from '@/components/Multipoll/Create/MultipollCreateForm.vue';
import PageTitleMixin from '@/mixins/PageTitleMixin';

export default (Vue as VueConstructor<Vue & InstanceType<typeof PageTitleMixin>>).extend({
  name: 'MultipollCreate',
  mixins: [PageTitleMixin],
  components: { MultipollCreateForm },
  mounted() {
    this.setPageTitle('Brontly multipoll - create new');
  },
});
