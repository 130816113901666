import { render, staticRenderFns } from "./MultipollSubmit.vue?vue&type=template&id=afebe98e&scoped=true&"
import script from "./MultipollSubmit.vue?vue&type=script&lang=ts&"
export * from "./MultipollSubmit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afebe98e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VSkeletonLoader})
