
import Vue from 'vue';

export default Vue.extend({
  methods: {
    getAuthToken(multipollId: string): string | undefined {
      return this.$cookies.get(`multipoll-auth-token-${multipollId}`);
    },
    setAuthToken(multipollId: string, authToken: string): void {
      this.$cookies.set(`multipoll-auth-token-${multipollId}`, authToken, '365d');
    },
    getAnswerToken(multipollId: string): string | undefined {
      return this.$cookies.get(`multipoll-answer-token-${multipollId}`);
    },
    setAnswerToken(multipollId: string, answerToken: string): void {
      this.$cookies.set(`multipoll-answer-token-${multipollId}`, answerToken, '365d');
    },
  },
});
