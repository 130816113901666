
import Vue, { VueConstructor } from 'vue';
import MultipollTokensMixin from '@/mixins/Multipoll/MultipollTokensMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof MultipollTokensMixin>>).extend({
  name: 'MultipollAnswerTokenRedirect',
  mixins: [MultipollTokensMixin],
  created() {
    this.setAnswerToken(this.multipollId, this.answerToken);
    console.log('redirecting');
    this.$router.replace({ name: 'multipoll.submit', params: { multipollId: this.multipollId } });
  },
  computed: {
    multipollId() {
      return this.$route.params.multipollId;
    },
    answerToken() {
      return this.$route.params.answerToken;
    },
  },
});
