<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MultipollEdit',
});
</script>
<template>
  <v-app>
    <v-app-bar app color="accent">
      <v-app-bar-title>
        <span>Edit multipoll</span>
      </v-app-bar-title>
    </v-app-bar>
    <v-main>
      Edit
    </v-main>
  </v-app>
</template>
<style scoped>

</style>
