
import Vue, { PropType } from 'vue';
import moment from 'moment';

interface State {
  input: Array<string>,
}

export default Vue.extend({
  name: 'MultiDatePicker',
  props: {
    value: Array as PropType<string[]>,
  },
  data(): State {
    return {
      input: this.value,
    };
  },
  methods: {
    isDateAllowed(input: string): boolean {
      return moment(input).isAfter(moment().subtract(1, 'day'));
    },
  },
  watch: {
    value(newValue) {
      this.input = newValue;
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
      this.$emit('valid', newValue.length > 0);
    },
  },
});
