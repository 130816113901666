
import Vue from 'vue';

export default Vue.extend({
  name: 'MultipollLayout',
  computed: {
    appBarTitle(): string {
      switch (this.$route.name) {
        case 'multipoll.create':
          return 'Create a new multipoll';
        case 'multipoll.submit':
          return 'Submit your input';
        default:
          return 'Brontly Multipoll';
      }
    },
  },
});
