
import Vue, { PropType, VueConstructor } from 'vue';
import { MultipollAnswer, MultipollChoiceInput, MultipollInfo } from '@/api/multipoll';
import ContactNameInput from '@/components/Multipoll/Create/ContactNameInput.vue';
import ContactEmailInput from '@/components/Multipoll/Create/ContactEmailInput.vue';
import MultipollChoiceInputs from '@/components/Multipoll/Submit/MultipollChoiceInputs.vue';
import MultipollTokensMixin from '@/mixins/Multipoll/MultipollTokensMixin.vue';

interface State {
  contactName: string | null,
  contactEmail: string | null,
  multipollChoiceInputs: Array<MultipollChoiceInput>,
  contactNameInputValid: boolean,
  contactEmailInputValid: boolean,
  multipollChoiceInputsValid: boolean,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof MultipollTokensMixin>>).extend({
  name: 'MultipollSubmitForm',
  components: { MultipollChoiceInputs, ContactNameInput, ContactEmailInput },
  mixins: [MultipollTokensMixin],
  props: {
    answerToken: {
      type: String,
      required: false,
    },
    multipoll: {
      type: Object as PropType<MultipollInfo>,
      required: true,
    },
    answer: {
      type: Object as PropType<MultipollAnswer>,
      required: false,
    },
    otherAnswers: {
      type: Array as PropType<MultipollAnswer[]>,
      required: true,
    },
  },
  data(): State {
    return {
      contactName: this.answer?.contactName,
      contactEmail: null,
      multipollChoiceInputs: this.answer?.multipollChoiceInputs ?? [],
      contactNameInputValid: false,
      contactEmailInputValid: true, // Optional
      multipollChoiceInputsValid: false,
    };
  },
  computed: {
    valid(): boolean {
      return this.contactNameInputValid && this.contactEmailInputValid && this.multipollChoiceInputsValid;
    },
  },
  methods: {
    submitMultipoll() {
      this.$apiMultipoll.submitMultipoll(this.multipoll.id, {
        contactName: this.contactName || '',
        contactEmail: this.contactEmail || undefined, // Optional
        multipollChoiceInputs: this.multipollChoiceInputs,
      }, this.answerToken)
        .then(response => {
          console.log('multipoll submitted successfully', response);
          this.setAnswerToken(this.multipoll.id, response.data.answerToken);
          this.$router.push({
            name: 'multipoll.summary',
            params: {
              multipollId: response.data.multipoll.id,
              multipollTitle: response.data.multipoll.title,
              createSuccess: 'true',
            },
          });
        })
        .catch(error => {
          console.error('multipoll submit failed', error);
        });
    },
  },
});
